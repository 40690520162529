'use client';

import { MantineProvider, createTheme } from '@mantine/core';
import {
  Button,
  Group,
  defaultVariantColorsResolver,
  VariantColorsResolver,
  parseThemeColor,
  rem,
  rgba,
  darken,
} from '@mantine/core';
import { ThemeProvider } from './theme-provider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { JitsuProvider } from '@jitsu/jitsu-react';

import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { AnimatePresence } from 'framer-motion';
import { Provider as StateProvider } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/utils';
import { getQueryClient } from './reactQueryProvider';
import { DevTools, useAtomsDebugValue } from 'jotai-devtools';
import { TRPCReactProvider } from '../trpc/trpc-react-provider';

const theme = createTheme({
  primaryColor: 'medpods',
  fontFamily: 'muli, sans-serif',
  colors: {
    medpods: [
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
    ],
  },
  headings: { fontFamily: 'muli, sans-serif' },
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme,
    });

    if (input.variant === 'filled') {
      return {
        background: rgba(parsedColor.value, 0.1),
        hover: rgba(parsedColor.value, 0.15),
        border: `${rem(1)} solid ${parsedColor.value}`,
        color: darken(parsedColor.value, 0.1),
      };
    }

    return defaultResolvedColors;
  },
});

export const metadata = {
  title: 'Medpods Telehealth',
  description: 'Your number #1 Tele health provider.',
};

// If loading a variable font, you don't need to specify the font weight
const HydrateAtoms = ({ children, queryClient }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

const DebugAtoms = () => {
  useAtomsDebugValue();
  return null;
};

export function Providers(props: { children: React.ReactNode }) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return (
    <MantineProvider
      theme={theme}
      cssVariablesSelector="html"
      // withCssVariables={false}
      forceColorScheme="light"
      // cssVariablesSelector="html"
      // withCssVariables={false}
      // withCSSVariables
      // withGlobalStyles
      // withNormalizeCSS
    >
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        forcedTheme="light"
        enableSystem={false}
        // disableTransitionOnChange
      >
        <AnimatePresence mode="wait">
          <QueryClientProvider client={queryClient}>
            <ReactQueryStreamedHydration queryClient={queryClient}>
              <TRPCReactProvider queryClient={queryClient}>
                <StateProvider>
                  {process.env.NODE_ENV !== 'production' && (
                    <>
                      {/* <ReactQueryDevtools position="bottom" />
                      <DevTools position="top-right" />
                      <DebugAtoms /> */}
                    </>
                  )}
                  <JitsuProvider
                    options={{
                      host: 'https://sync.doto.business',
                      writeKey: process.env.NEXT_PUBLIC_JITSU_WRITE_KEY,
                    }}
                  >
                    <HydrateAtoms queryClient={queryClient}>
                      {props.children}
                    </HydrateAtoms>
                  </JitsuProvider>
                </StateProvider>
              </TRPCReactProvider>
            </ReactQueryStreamedHydration>
          </QueryClientProvider>
        </AnimatePresence>
      </ThemeProvider>
    </MantineProvider>
  );
}
