import Image from 'next/image';
import base from './base';

import light from './light';
export default function Logo({
  variant = 'base',
}: {
  variant?: 'base' | 'light';
}): JSX.Element {
  return (
    <div className="w-[92px] h-[64px] sm:h-[92px] sm:w-[128px] relative">
      <img src={{ base, light }[variant]} alt="medpods logo" />
    </div>
  );
}
